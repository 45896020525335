import React from 'react';

import {
  useAuthStore,
  useLicenseStore,
} from '@app/stores';

import {
  RouterOutletPart,
  ThemeProviderPart,
  NotifyPart,
  LoaderPart,
  LocalizationProviderPart,
  LicenseNotifyPart,
} from './parts';

import { ProgressCircularFullpageBlock } from '@app/components';


export const App = () => {
  const { signinCurrent, user } = useAuthStore();
  const { loading, checkActive } = useLicenseStore();

  React.useEffect(() => {
    if (user === null) return;
    checkActive();
  }, [
    user,
    checkActive,
  ]);

  React.useEffect(() => {
    signinCurrent();
  }, [
    signinCurrent,
  ]);

  if (loading) return <ProgressCircularFullpageBlock />

  return (
    <LocalizationProviderPart>
      <ThemeProviderPart>
        <RouterOutletPart />
        <NotifyPart />
        <LoaderPart />
        <LicenseNotifyPart />
      </ThemeProviderPart>
    </LocalizationProviderPart>
  );
}
