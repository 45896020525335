import React from 'react';

import { PageBaseLayout, UserCreateFeature } from '@app/components';
import { useBreadcrumbsStore } from '@app/stores';


export const UserCreatePage = () => {
  const {
    breadcrumbs,
    breadcrumbsUserCreateSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsUserCreateSet();
  }, [
    breadcrumbsUserCreateSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <UserCreateFeature />
    </PageBaseLayout>
  );
};
