import {
  ProgressCardBlock,
  TransitionOpacityBrick,
} from "@app/components";

import { useMainContext } from "../../context";
import { OneComponent } from "../one";
import styles from './main.module.scss';


export const MainComponent = () => {
  const {
    materialList,
    materialListIsLoading,
  } = useMainContext();

  if (materialListIsLoading) return <ProgressCardBlock />;

  return (
    <TransitionOpacityBrick>
      <div className={styles['main']}>
        {materialList.map((item) => (
          <OneComponent item={item} key={item.id} />
        ))}
      </div>
    </TransitionOpacityBrick>
  );
};
