import {
  ButtonBackBlock,
  FormCenteredBlock,
  ButtonSaveBlock,
  FieldTextBlock,
  FieldSelectBlock,
  FieldBooleanBlock,
  FieldMultiselectBlock,
  FieldNumberBlock,
  CardDataComposition,
} from "@app/components";

import { useMainApi } from '../../context';


export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSubmitting,
    userListIsLoading,
    userList,
    changeFormValue,
    onSubmit,
    setError,
  } = useMainApi();

  return (
    <CardDataComposition
      title='Новый материал'
      extra={<ButtonBackBlock buttonIcon />}
      loading={userListIsLoading}
    >
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <>
            <ButtonSaveBlock
              loading={formIsSubmitting}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonBackBlock />
          </>
        }
      >
        <FieldTextBlock
          label="Название"
          name="name"
          value={formValue.name}
          onChange={changeFormValue}
          error={setError('name')}
        />
        <FieldSelectBlock
          label="Тип"
          name="type"
          value={formValue.type}
          onChange={changeFormValue}
          error={setError('type')}
          items={[
            { label: 'Фрейм', value: 'frame' },
            { label: 'Ссылка', value: 'link' },
          ]}
        />
        <FieldTextBlock
          label="Frame src"
          name="frameSrc"
          value={formValue.frameSrc}
          onChange={changeFormValue}
          error={setError('frameSrc')}
        />
        <FieldTextBlock
          label="Изображение"
          name="coverSrc"
          value={formValue.coverSrc}
          onChange={changeFormValue}
          error={setError('coverSrc')}
        />
        <FieldBooleanBlock
          label="Заглушка"
          name="plug"
          value={formValue.plug}
          onChange={changeFormValue}
          error={setError('coverSrc')}
        />
        <FieldMultiselectBlock
          label="Пользователи"
          name="users"
          value={formValue.users}
          onChange={changeFormValue}
          error={setError('users')}
          items={userList.map((user) => ({ value: user.id, label: user.name + ' ' + (user.lastName || '') }))}
        />
        <FieldNumberBlock
          label="Приоритет сортировки"
          name="priorityOrder"
          value={formValue.priorityOrder}
          onChange={changeFormValue}
          error={setError('priorityOrder')}
        />
      </FormCenteredBlock>
    </CardDataComposition>
  );
};
