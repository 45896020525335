export const translateRoleHelper = (role: 'admin' | 'user'): string => {
  if (role === 'admin') {
    return 'Администратор';
  }

  if (role === 'user') {
    return 'Пользователь'
  }

  return role;
}
