import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from "@app/components";

import { useMainApi, useUpdateApi } from '../../context';
import styles from './update.module.scss';


export const UpdateComponent = () => {
  const {
    userGeneralIsEditing,
    userGeneralFormClose,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    formIsSubmitting,
    themeEditor,
    onSubmit,
    setError,
    changeFormValue,
    changeHtmlText,
  } = useUpdateApi();

  if (formValue === null || !userGeneralIsEditing) return null;

  return (
    <div className={styles['update']}>
      <FormCenteredBlock
        onSubmit={onSubmit}
        buttons={
          <>
            <ButtonSaveBlock
              loading={formIsSubmitting}
              onClick={onSubmit}
              disabled={!formDirty}
            />
            <ButtonCancelBlock onClick={userGeneralFormClose}/>
          </>
        }
      >
        <FieldTextBlock
          label="Имя"
          name="name"
          value={formValue.name}
          onChange={changeFormValue}
          error={setError('name')}
        />
        <FieldTextBlock
          label="Отчество"
          name="secondName"
          value={formValue.secondName}
          onChange={changeFormValue}
          error={setError('secondName')}
        />
        <FieldTextBlock
          label="Фамилия"
          name="lastName"
          value={formValue.lastName}
          onChange={changeFormValue}
          error={setError('lastName')}
        />
        <FieldTextBlock
          label="Электронная почта"
          name="email"
          value={formValue.email}
          onChange={changeFormValue}
          error={setError('email')}
        />
        <FieldSelectBlock
          label="Роль"
          name="role"
          value={formValue.role}
          onChange={changeFormValue}
          error={setError('role')}
          items={[{ value: 'admin', label: 'Администратор' }, { value: 'user', label: 'Пользователь' }]}
        />
        <div className={styles['update__html-text']}>
          <CodeMirror
            value={formValue.welcomeText}
            height="150px"
            extensions={[html()]}
            onChange={(value) => changeHtmlText(value, 'welcomeText')}
            theme={themeEditor}
          />
        </div>
        <div className={styles['update__html-text']}>
          <CodeMirror
            value={formValue.topPanelText}
            height="150px"
            extensions={[html()]}
            onChange={(value) => changeHtmlText(value, 'topPanelText')}
            theme={themeEditor}
          />
        </div>
        <div className={styles['update__html-text']}>
          <CodeMirror
            value={formValue.bottomPanelText}
            height="150px"
            extensions={[html()]}
            onChange={(value) => changeHtmlText(value, 'bottomPanelText')}
            theme={themeEditor}
          />
        </div>
      </FormCenteredBlock>
    </div>
  );
};
