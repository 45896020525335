import React from 'react';

import { useAppStore } from '@app/stores';

import { ChangeFieldEventType } from '@app/types';

import { translateErrorHelper } from '@app/helpers';
import { useMainApi } from '..';
import { UserApi } from '@app/api';


type FormValueType = {
  password: string;
  passwordConfirm: string;
};

export interface ChangePasswordContextInterface {
  formValue: FormValueType;
  buttonIsDisabled: boolean;
  formIsSubmitting: boolean;
  closeDialog: () => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  changeFormValue: (e: ChangeFieldEventType) => void;
  setError: (fieldName: string) => string | null;
};

const initialFormValue = {
  password: '',
  passwordConfirm: '',
};

export const useChangePasswordHook = (
): ChangePasswordContextInterface => {
  const {
    userCurrent,
    errors,
    modalChangePasswordClose,
  } = useMainApi();
  
  const [formValue, formValueSet] = React.useState(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [formIsSubmitting, formIsSubmittingSet] = React.useState(false);
  const [formSended, formSendedSet] = React.useState(false);
  const { notifyCall } = useAppStore();

  const closeDialog = React.useCallback(() => {
    modalChangePasswordClose();
    formDirtySet(false);
    formSendedSet(false);
    formValueSet(initialFormValue);
  }, [
    modalChangePasswordClose,
  ]);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    formIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (userCurrent === null) return;
    formSendedSet(true);

    const response = await UserApi.passwordUpdate({
      password: formValue.password,
      id: userCurrent.id,
    });

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось обновить пароль',
      });
      formIsSubmittingSet(false);
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Пароль успешно обновлен',
    });

    formIsSubmittingSet(false);
    closeDialog();
  }, [
    formValue,
    userCurrent,
    closeDialog,
    notifyCall,
  ]);

  const changeFormValue= React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const setError = React.useCallback((fieldName: string) => {
    return formSended
      ? errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0])
      : '';
  }, [
    errors,
    formSended,
  ]);

  const buttonIsDisabled = !formDirty || (
    (formValue.password === '' || formValue.passwordConfirm === '') ||
    (formValue.password !== formValue.passwordConfirm)
  );

  return React.useMemo(() => ({
    formValue,
    buttonIsDisabled,
    formIsSubmitting,
    closeDialog,
    onSubmit,
    changeFormValue,
    setError,
  }), [
    formValue,
    buttonIsDisabled,
    formIsSubmitting,
    closeDialog,
    onSubmit,
    changeFormValue,
    setError,
  ]);
};
