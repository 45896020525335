import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import {
  ButtonBackBlock,
  FormCenteredBlock,
  ButtonSaveBlock,
  CardDataComposition,
  FieldTextBlock,
  FieldSelectBlock,
  FieldPasswordBlock,
} from "@app/components";

import { useMainApi } from '../../context';
import styles from './main.module.scss';


export const MainComponent = () => {
  const {
    formValue,
    formDirty,
    formIsSubmitting,
    themeEditor,
    changeFormValue,
    changeHtmlText,
    onSubmit,
    setError,
  } = useMainApi();

  return (
    <div className={styles['main']}>
      <CardDataComposition
        title='Новый пользователь'
        extra={<ButtonBackBlock buttonIcon />}
        loading={formIsSubmitting}
      >
        <FormCenteredBlock
          onSubmit={onSubmit}
          buttons={
            <>
              <ButtonSaveBlock
                loading={formIsSubmitting}
                onClick={onSubmit}
                disabled={!formDirty}
              />
              <ButtonBackBlock />
            </>
          }
        >
          <FieldTextBlock
            label="Имя"
            name="name"
            value={formValue.name}
            onChange={changeFormValue}
            error={setError('name')}
            required
          />
          <FieldTextBlock
            label="Отчество"
            name="secondName"
            value={formValue.secondName}
            onChange={changeFormValue}
            error={setError('secondName')}
            required
          />
          <FieldTextBlock
            label="Фамилия"
            name="lastName"
            value={formValue.lastName}
            onChange={changeFormValue}
            error={setError('lastName')}
            required
          />
          <FieldTextBlock
            label="Электронная почта"
            name="email"
            value={formValue.email}
            onChange={changeFormValue}
            error={setError('email')}
            required
          />
          <FieldPasswordBlock
            label="Пароль"
            name="password"
            value={formValue.password}
            onChange={changeFormValue}
            error={setError('password')}
            required
          />
          <FieldSelectBlock
            label="Роль"
            name="role"
            value={formValue.role}
            onChange={changeFormValue}
            error={setError('role')}
            items={[{ value: 'admin', label: 'Администратор' }, { value: 'user', label: 'Пользователь' }]}
            required
          />
          <div className={styles['main__html-text']}>
            <CodeMirror
              value={formValue.welcomeText}
              height="150px"
              extensions={[html()]}
              onChange={(value) => changeHtmlText(value, 'welcomeText')}
              theme={themeEditor}
            />
          </div>
          <div className={styles['main__html-text']}>
            <CodeMirror
              value={formValue.topPanelText}
              height="150px"
              extensions={[html()]}
              onChange={(value) => changeHtmlText(value, 'topPanelText')}
              theme={themeEditor}
            />
          </div>
          <div className={styles['main__html-text']}>
            <CodeMirror
              value={formValue.bottomPanelText}
              height="150px"
              extensions={[html()]}
              onChange={(value) => changeHtmlText(value, 'bottomPanelText')}
              theme={themeEditor}
            />
          </div>
        </FormCenteredBlock>
      </CardDataComposition>
    </div>
  );
};
