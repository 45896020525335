import {
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
} from "@app/components";

import {
  translateRoleHelper,
  translateDateHelper,
} from '@app/helpers';

import { useMainApi } from '../../context';


export const TableComponent = () => {
  const {
    userCurrent,
    userGeneralIsEditing,
  } = useMainApi();

  if (userCurrent === null || userGeneralIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="users">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>ID</TableCellBrick>
            <TableCellBrick>Имя</TableCellBrick>
            <TableCellBrick>Отчество</TableCellBrick>
            <TableCellBrick>Фамилия</TableCellBrick>
            <TableCellBrick>Электронная почта</TableCellBrick>
            <TableCellBrick>Роль</TableCellBrick>
            <TableCellBrick>Дата создания</TableCellBrick>
            <TableCellBrick>Дата обновления</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCellBrick>{userCurrent.id}</TableCellBrick>
            <TableCellBrick>{userCurrent.name}</TableCellBrick>
            <TableCellBrick>{userCurrent.lastName}</TableCellBrick>
            <TableCellBrick>{userCurrent.secondName}</TableCellBrick>
            <TableCellBrick>{userCurrent.email}</TableCellBrick>
            <TableCellBrick>{translateRoleHelper(userCurrent.role)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(userCurrent.createdAt)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(userCurrent.updatedAt)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};
