import { ListIcon, FabBrick, FrameFeature, TransitionOpacityBrick } from '@app/components'
import { AppBarComponent, DrawerComponent } from '@app/components/layouts/page-base/components';
import styles from './frame.module.scss';
import { useNavigate } from 'react-router-dom';


export const FramePage = () => {
  const navigate = useNavigate();
  
  return (
    <>
      <div className={styles['frame']}>
        <div className={styles['frame__app-bar']}>
          <AppBarComponent />
        </div>
        <TransitionOpacityBrick>
          <div className={styles['frame__content']}>
            <FrameFeature />
          </div>
        </TransitionOpacityBrick>
      </div>

      <div className={styles['frame-menu-button']}>
        <FabBrick onClick={() => navigate('/')} color="primary" aria-label="add">
          <ListIcon />
        </FabBrick>
      </div>
      <DrawerComponent />
    </>
  );
};
