import React from 'react';

import { PageBaseLayout, MaterialCreateFeature } from '@app/components';
import { useBreadcrumbsStore } from '@app/stores';


export const MaterialCreatePage = () => {
  const {
    breadcrumbs,
    breadcrumbsMaterialCreateSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsMaterialCreateSet();
  }, [
    breadcrumbsMaterialCreateSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <MaterialCreateFeature />
    </PageBaseLayout>
  );
};
