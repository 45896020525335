import {
  GeneralComponent,
  TableComponent,
  UpdateComponent,
} from '..';

import { UpdateProvider } from "../../context";

export const MainComponent = () => {
  return (
    <GeneralComponent>
      <TableComponent />

      <UpdateProvider>
        <UpdateComponent />
      </UpdateProvider>
    </GeneralComponent>
  );
};
