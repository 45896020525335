import { Typography } from '@mui/material';

import {
  LinkBrick,
  PageCenteringLayout,
} from '@app/components';

import styles from './not-found.module.scss';


export const NotFoundPage = () => {
  return (
    <PageCenteringLayout>
      <div className={styles['not-found-page']}>
        <div className={styles['not-found-page__content']}>
          <div className={styles['not-found-page__emoji']}></div>
          <Typography variant="h1">🤷‍♂️ Упс!</Typography>
          <Typography variant="body1">Такой страницы не существует в текущей реальности..</Typography>
          <div className={styles['not-found-page__links']}></div>
          <LinkBrick to="/" label="На главную" />
        </div>
      </div>
    </PageCenteringLayout>
  );
};
