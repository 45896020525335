import {
  KeyIcon,
  ButtonIconBrick,
  ButtonEditBlock,
  ButtonBackBlock,
  ButtonCancelBlock,
  CardDataComposition,
} from "@app/components";

import { useMainApi } from '../../context';


export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    id,
    userOneIsLoading,
    userGeneralIsEditing,
    userGeneralFormOpen,
    userGeneralFormClose,
    modalChangePasswordOpen,
  } = useMainApi();

  return (
    <CardDataComposition
      title={'Пользователь №' + id}
      extra={
        <>
          {!userGeneralIsEditing && (
            <ButtonIconBrick onClick={modalChangePasswordOpen}>
              <KeyIcon />
            </ButtonIconBrick>
          )}
          {!userGeneralIsEditing && <ButtonEditBlock onClick={() => userGeneralFormOpen()} />}
          {!userGeneralIsEditing && <ButtonBackBlock buttonIcon />}
          {userGeneralIsEditing && <ButtonCancelBlock onClick={() => userGeneralFormClose()} buttonIcon />}
        </>
      }
      loading={userOneIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
