import React from 'react';

import { useAppStore, useAuthStore } from '@app/stores';
import { UserType } from '@app/types';
import { MaterialApi } from '@app/api';


export interface MainContextInterface {
  user: UserType | null;
  materialListLength: number;
  getGreeting: () => string;
};

export const useMainHook = (
): MainContextInterface => {
  const [ materialListLength, materialListLengthSet ] = React.useState(0);
  const { notifyCall } = useAppStore();
  const { user } = useAuthStore();

  const getMaterialList = React.useCallback(async () => {
    const response = await MaterialApi.getMaterialForUserList();

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось загрузить материалы',
      });
    }

    materialListLengthSet(response.data.materials.length);
  }, [
    notifyCall,
  ]);

  const getGreeting = React.useCallback((): string => {
    const hours = new Date().getHours();
    
    if (hours >= 0 && hours < 6) return 'Доброй ночи';
    if (hours >= 6 && hours < 12) return 'Доброе утро';
    if (hours >= 12 && hours < 18) return 'Добрый день';
    if (hours >= 18 && hours <= 23) return 'Добрый вечер';
    
    return '';
  }, []);

  React.useEffect(() => {
    getMaterialList();
  }, [
    getMaterialList,
  ]);

  return React.useMemo(() => ({
    user,
    materialListLength,
    getGreeting,
  }), [
    user,
    materialListLength,
    getGreeting,
  ]);
};
