import {
  CardContentBrick,
  TypographyBrick,
  CardDataComposition,
} from "@app/components";

import { useMainHook } from "../../context";
import styles from './main.module.scss';


export const MainComponent = () => {
  const {
    user,
    materialListLength,
    getGreeting,
  } = useMainHook();

  if (materialListLength === 0) return null;

  return (
    <div className={styles['main']}>
      <CardDataComposition
        title={`${getGreeting()}, ${user?.name}${(user?.secondName ? ' ' + user?.secondName : '')}!`}
      >
        <CardContentBrick>
          <div className={styles['main__count']}>
            <TypographyBrick variant="body1">
              Вам доступно {materialListLength} материалов для работы.
            </TypographyBrick>
          </div>

          {
            user?.welcomeText
              ? <div className="html-text" dangerouslySetInnerHTML={{ __html: user!.welcomeText }} />
              : ''
          }
        </CardContentBrick>
      </CardDataComposition>
    </div>
  );
};
