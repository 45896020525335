import { useNavigate } from 'react-router-dom';

import {
  ScrollXZoneBrick,
  LinkBrick,
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
  ButtonCreateBlock,
  CardDataComposition,
} from "@app/components";

import {
  translateRoleHelper,
  translateDateHelper,
} from '@app/helpers';

import { useMainApi } from '../../context';


export const MainComponent = () => {
  const navigate = useNavigate();
  const {
    userList,
    userListIsLoading,
  } = useMainApi();

  return (
    <CardDataComposition
      title="Пользователи"
      noData={userList.length === 0}
      extra={
        <ButtonCreateBlock
          onClick={() => navigate('/user-list/create')}
          buttonIcon
        />
      }
      loading={userListIsLoading}
    >
      <ScrollXZoneBrick>
        <TableContainerBrick>
          <TableBrick aria-label="users">
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>ID</TableCellBrick>
                <TableCellBrick>Электронная почта</TableCellBrick>
                <TableCellBrick>Имя</TableCellBrick>
                <TableCellBrick>Отчество</TableCellBrick>
                <TableCellBrick>Фамилия</TableCellBrick>
                <TableCellBrick>Роль</TableCellBrick>
                <TableCellBrick>Дата создания</TableCellBrick>
                <TableCellBrick>Дата обновления</TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              {userList.map((item) => (
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.id}
                >
                  <TableCellBrick component="th" scope="row">
                    {item.id}
                  </TableCellBrick>
                  <TableCellBrick>
                    <LinkBrick to={'/user-list/' + item.id} label={item.email} />
                  </TableCellBrick>
                  <TableCellBrick>{item.name}</TableCellBrick>
                  <TableCellBrick>{item.lastName}</TableCellBrick>
                  <TableCellBrick>{item.secondName}</TableCellBrick>
                  <TableCellBrick>{translateRoleHelper(item.role)}</TableCellBrick>
                  <TableCellBrick>{translateDateHelper(item.createdAt)}</TableCellBrick>
                  <TableCellBrick>{translateDateHelper(item.updatedAt)}</TableCellBrick>
                </TableRowBrick>
              ))}
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      </ScrollXZoneBrick>
    </CardDataComposition>
  );
};
