import { useAuthStore } from "@app/stores";

import { CardBrick, CardContentBrick } from "@app/components";
import styles from './panel-top.module.scss';


export const PanelTopFeature = () => {
  const { user } = useAuthStore();

  if (!user!.topPanelText) return null;
  
  return (
    <CardBrick className={styles['panel-top']}>
      <CardContentBrick>
        {<div className="html-text" dangerouslySetInnerHTML={{ __html: user!.topPanelText }} />}
      </CardContentBrick>
    </CardBrick>
  );
};
