import React from 'react';

import { PageBaseLayout, MaterialListFeature } from '@app/components';
import { useBreadcrumbsStore } from '@app/stores';


export const MaterialListPage = () => {
  const {
    breadcrumbs,
    breadcrumbsMaterialListSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsMaterialListSet();
  }, [
    breadcrumbsMaterialListSet,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <MaterialListFeature />
    </PageBaseLayout>
  );
};
