import { useNavigate } from 'react-router-dom';

import { Box, Chip } from '@mui/material';

import {
  LinkBrick,
  ScrollXZoneBrick,
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
  ButtonCreateBlock,
  CardDataComposition,
} from "@app/components";

import { translateDateHelper } from '@app/helpers';

import { useMainApi } from '../../context';


export const MainComponent = () => {
  const navigate = useNavigate();
  const {
    materialList,
    materialListIsLoading,
  } = useMainApi();

  return (
    <CardDataComposition
      title="Материалы"
      noData={materialList.length === 0}
      extra={
        <ButtonCreateBlock
          onClick={() => navigate('/material-list/create')}
          buttonIcon
        />
      }
      loading={materialListIsLoading}
    >
      <ScrollXZoneBrick>
        <TableContainerBrick>
          <TableBrick aria-label="materials">
            <TableHeadBrick>
              <TableRowBrick>
                <TableCellBrick>ID</TableCellBrick>
                <TableCellBrick>Название</TableCellBrick>
                <TableCellBrick>Фрейм</TableCellBrick>
                <TableCellBrick>Пользователи</TableCellBrick>
                <TableCellBrick>Заглушка</TableCellBrick>
                <TableCellBrick>Приоритет</TableCellBrick>
                <TableCellBrick>Дата создания</TableCellBrick>
                <TableCellBrick>Дата обновления</TableCellBrick>
              </TableRowBrick>
            </TableHeadBrick>
            <TableBodyBrick>
              {materialList.map((item) => (
                <TableRowBrick
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={item.id}
                >
                  <TableCellBrick component="th" scope="row">
                    {item.id}
                  </TableCellBrick>
                  <TableCellBrick>
                    <LinkBrick to={'/material-list/' + item.id} label={item.name} />
                  </TableCellBrick>
                  <TableCellBrick>
                    {item.type === 'frame' ? (
                      <a rel="noreferrer" target="_blank" href={'/' + item.id}>Фрейм</a>
                    ) : (
                      <a rel="noreferrer" target="_blank" href={item.frameSrc}>Ссылка</a>
                    )}
                  </TableCellBrick>
                  <TableCellBrick>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {item.users.map((user) => (
                        <Chip key={user.id} label={`${user.name} ${user.lastName || ''}`} />
                      ))}
                    </Box>
                  </TableCellBrick>
                  <TableCellBrick>{item.plug ? 'Да' : 'Нет'}</TableCellBrick>
                  <TableCellBrick>{item.priorityOrder}</TableCellBrick>
                  <TableCellBrick>{translateDateHelper(item.createdAt)}</TableCellBrick>
                  <TableCellBrick>{translateDateHelper(item.updatedAt)}</TableCellBrick>
                </TableRowBrick>
              ))}
            </TableBodyBrick>
          </TableBrick>
        </TableContainerBrick>
      </ScrollXZoneBrick>
    </CardDataComposition>
  );
};
