import React from 'react';
import { MaterialType } from '@app/types';
import { MaterialApi } from '@app/api';
import { useAppStore } from '@app/stores';


export interface MainContextInterface {
  materialList: MaterialType[];
  materialListIsLoading: boolean;
};

export const useMainHook = (
): MainContextInterface => {
  const [ materialList, materialListSet ] = React.useState<MaterialType[]>([]);
  const [ materialListIsLoading, materialListIsLoadingSet ] = React.useState(false);
  const { notifyCall } = useAppStore();

  const getMaterialList = React.useCallback(async () => {
    materialListIsLoadingSet(true);
    const response = await MaterialApi.getList();

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось загрузить материалы',
      });
    }

    materialListSet(response.data.materials);
    materialListIsLoadingSet(false);
  }, [
    notifyCall,
  ]);

  React.useEffect(() => {
    getMaterialList();
  }, [
    getMaterialList,
  ]);

  return React.useMemo(() => ({
    materialList,
    materialListIsLoading,
  }), [
    materialList,
    materialListIsLoading,
  ]);
};
