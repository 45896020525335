import { Box, Chip } from '@mui/material';

import {
  TableContainerBrick,
  TableBrick,
  TableHeadBrick,
  TableBodyBrick,
  TableRowBrick,
  TableCellBrick,
} from "@app/components";

import { translateDateHelper } from '@app/helpers';

import { useMainApi } from '../../context';

export const TableComponent = () => {
  const {
    materialCurrent,
    materialGeneralIsEditing,
  } = useMainApi();

  if (materialCurrent === null || materialGeneralIsEditing) return null;

  return (
    <TableContainerBrick>
      <TableBrick aria-label="users">
        <TableHeadBrick>
          <TableRowBrick>
            <TableCellBrick>ID</TableCellBrick>
            <TableCellBrick>Название</TableCellBrick>
            <TableCellBrick>Фрейм</TableCellBrick>
            <TableCellBrick>Пользователи</TableCellBrick>
            <TableCellBrick>Заглушка</TableCellBrick>
            <TableCellBrick>Приоритет</TableCellBrick>
            <TableCellBrick>Дата создания</TableCellBrick>
            <TableCellBrick>Дата обновления</TableCellBrick>
          </TableRowBrick>
        </TableHeadBrick>
        <TableBodyBrick>
          <TableRowBrick sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCellBrick>{materialCurrent.id}</TableCellBrick>
            <TableCellBrick>{materialCurrent.name}</TableCellBrick>
            <TableCellBrick>
              {materialCurrent.type === 'frame' ? (
                <a rel="noreferrer" target="_blank" href={'/' + materialCurrent.id}>Фрейм</a>
              ) : (
                <a rel="noreferrer" target="_blank" href={materialCurrent.frameSrc}>Ссылка</a>
              )}
            </TableCellBrick>
            <TableCellBrick>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {materialCurrent.users.map((user) => (
                  <Chip key={user.id} label={user.name} />
                ))}
              </Box>
            </TableCellBrick>
            <TableCellBrick>{materialCurrent.plug ? 'Да' : 'Нет'}</TableCellBrick>
            <TableCellBrick>{materialCurrent.priorityOrder}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(materialCurrent.createdAt)}</TableCellBrick>
            <TableCellBrick>{translateDateHelper(materialCurrent.updatedAt)}</TableCellBrick>
          </TableRowBrick>
        </TableBodyBrick>
      </TableBrick>
    </TableContainerBrick>
  );
};
