import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import {
  AnonGuardHoc,
  AuthGuardHoc,
  HomePage,
  NotFoundPage,
  PersonalPage,
  SigninPage,
  MaterialListPage,
  MaterialCreatePage,
  MaterialOnePage,
  UserListPage,
  UserCreatePage,
  UserOnePage,
  FramePage,
  LicenseExpiredPage,
  LicenseGuardHoc,
  LicensePage,
} from '@app/components';


export const RouterOutletPart = () => {
  const router = createBrowserRouter([
    { path: '/', element: <AuthGuardHoc><LicenseGuardHoc><HomePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/:id', element: <AuthGuardHoc><LicenseGuardHoc><FramePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/personal', element: <AuthGuardHoc><LicenseGuardHoc><PersonalPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/material-list', element: <AuthGuardHoc><LicenseGuardHoc><MaterialListPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/material-list/create', element: <AuthGuardHoc><LicenseGuardHoc><MaterialCreatePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/material-list/:id', element: <AuthGuardHoc><LicenseGuardHoc><MaterialOnePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/user-list', element: <AuthGuardHoc><LicenseGuardHoc><UserListPage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/user-list/create', element: <AuthGuardHoc><LicenseGuardHoc><UserCreatePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/user-list/:id', element: <AuthGuardHoc><LicenseGuardHoc><UserOnePage /></LicenseGuardHoc></AuthGuardHoc> },
    { path: '/signin', element: <AnonGuardHoc><SigninPage /></AnonGuardHoc> },
    { path: '/license', element: <AuthGuardHoc><LicensePage /></AuthGuardHoc> },
    { path: '/license-expired', element: <AuthGuardHoc><LicenseExpiredPage /></AuthGuardHoc> },

    { path: '*', element: <NotFoundPage /> },
  ]);

  return (
    <RouterProvider router={router} />
  );
};