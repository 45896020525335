import React from 'react';
import { useNavigate } from 'react-router-dom';
import { EditorView } from '@codemirror/view';

import { useAppStore } from '@app/stores';
import { UserApi } from '@app/api';

import {
  BackendErrorsType,
  ChangeFieldEventType,
} from '@app/types';

import { translateErrorHelper } from '@app/helpers';
import { Extension } from '@codemirror/state';

const initialFormValue = {
  name: '',
  secondName: '',
  lastName: '',
  email: '',
  password: '',
  role: null,
  welcomeText: '',
  topPanelText: '',
  bottomPanelText: '',
};

type FormValueType = {
  name: string;
  secondName: string;
  lastName: string;
  email: string;
  password: string;
  role: 'admin' | 'user' | null;
  welcomeText: string;
  topPanelText: string;
  bottomPanelText: string;
};

export interface MainContextInterface {
  formValue: FormValueType;
  formDirty: boolean;
  formIsSubmitting: boolean;
  themeEditor: Extension;
  changeFormValue: (e: ChangeFieldEventType) => void;
  changeHtmlText: (value: string, name: string) => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const [formValue, formValueSet] = React.useState<FormValueType>(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [formIsSubmitting, formIsSubmittingSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);
  const { notifyCall } = useAppStore();
  const { themeMode } = useAppStore();

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      }
    });
  }, []);

  const changeHtmlText = React.useCallback((
    value: string,
    name: string,
  ) => {
    formDirtySet(true);
    formValueSet((state) => {
      return {
        ...state,
        [name]: value,
      }
    });
  }, []);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    formIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return;

    const response = await UserApi.create(formValue);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось создать пользователя',
      });

      formIsSubmittingSet(false);
      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Пользователь успешно создан',
    });

    navigate('/user-list/' + response.data.user.id);

    formIsSubmittingSet(false); 
  }, [
    formValue,
    notifyCall,
    navigate,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  const ivory = (themeMode === 'light' ? "#000" : "#abb2bf"),
    stone = (themeMode === 'light' ? "#000" : "#7d8799"),
    darkBackground = (themeMode === 'light' ? "red" : "#21252b"),
    highlightBackground = (themeMode === 'light' ? "#e9e9e9" : "#2c313a"),
    background = (themeMode === 'light' ? "#f0f0f0" : "#323232"),
    tooltipBackground = (themeMode === 'light' ? "#e0e0e0" : "#252525"),
    selection = (themeMode === 'light' ? "#e0e0e0" : "#3e4451"),
    cursor = "#528bff",
    activeLine = (themeMode === 'light' ? "#e0e0e0" : "#6699ff0b");

  let themeEditor = EditorView.theme({
    "&": {
      color: ivory,
      backgroundColor: background,
      borderBottom: "1px solid #8b8b8b",
    },
    ".cm-content": {
      caretColor: cursor,
    },
    ".cm-cursor, .cm-dropCursor": {
      borderLeftColor: cursor,
    },
    "&.cm-focused > .cm-scroller > .cm-selectionLayer .cm-selectionBackground": {
      backgroundColor: selection,
    },
    ".cm-selectionBackground, .cm-content ::selection": {
      backgroundColor: selection,
    },
    ".cm-panels": {
      backgroundColor: darkBackground,
      color: ivory,
    },
    ".cm-panels.cm-panels-top": {
      borderBottom: "2px solid black",
    },
    ".cm-panels.cm-panels-bottom": {
      borderTop: "2px solid black",
    },
    ".cm-searchMatch": {
      backgroundColor: "#72a1ff59",
      outline: "1px solid #457dff",
    },
    ".cm-searchMatch.cm-searchMatch-selected": {
      backgroundColor: "#6199ff2f",
    },
    ".cm-activeLine": {
      backgroundColor: activeLine,
    },
    ".cm-selectionMatch": {
      backgroundColor: "#aafe661a",
    },
    "&.cm-focused .cm-matchingBracket, &.cm-focused .cm-nonmatchingBracket": {
      backgroundColor: "#bad0f847",
    },
    ".cm-gutters": {
      backgroundColor: background,
      color: stone,
      border: "none",
    },
    ".cm-activeLineGutter": {
      backgroundColor: highlightBackground,
    },
    ".cm-foldPlaceholder": {
      backgroundColor: "transparent",
      border: "none",
      color: "#ddd",
    },
    ".cm-tooltip": {
      border: "none",
      backgroundColor: tooltipBackground,
    },
    ".cm-tooltip .cm-tooltip-arrow:before": {
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
    },
    ".cm-tooltip .cm-tooltip-arrow:after": {
      borderTopColor: tooltipBackground,
      borderBottomColor: tooltipBackground,
    },
    ".cm-tooltip-autocomplete": {
      "& > ul > li[aria-selected]": {
        backgroundColor: highlightBackground,
        color: ivory,
      }
    },
  })

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    themeEditor,
    changeFormValue,
    changeHtmlText,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    themeEditor,
    changeFormValue,
    changeHtmlText,
    onSubmit,
    setError,
  ]);
};
