import React from 'react';
import { useParams } from 'react-router-dom';

import { UserApi } from '@app/api';
import { useAppStore } from '@app/stores';
import { BackendErrorsType, UserType } from '@app/types';


export interface MainContextInterface {
  id: string | undefined;
  userCurrent: UserType | null;
  userOneIsLoading: boolean;
  userGeneralIsEditing: boolean;
  errors: BackendErrorsType | null;
  modalChangePasswordOpened: boolean;
  userCurrentSet: React.Dispatch<React.SetStateAction<UserType | null>>;
  userGeneralFormOpen: () => void;
  userGeneralFormClose: () => void;
  modalChangePasswordOpen: () => void;
  modalChangePasswordClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const [userCurrent, userCurrentSet] = React.useState<UserType | null>(null);
  const [userOneIsLoading, userOneIsLoadingSet] = React.useState(false);
  const [userGeneralIsEditing, userGeneralIsEditingSet] = React.useState(false);
  const [modalChangePasswordOpened, modalChangePasswordOpenedSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);
  const { notifyCall } = useAppStore();

  const getUserOne = React.useCallback(async () => {
    userOneIsLoadingSet(true);
    const response = await UserApi.getOne((Number(id)));

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось загрузить пользователя',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    userCurrentSet(response.data.user);
    userOneIsLoadingSet(false);
  }, [
    id,
    notifyCall,
  ]);

  const userGeneralFormOpen = React.useCallback(() => {
    userGeneralIsEditingSet(true);
  }, []);

  const userGeneralFormClose = React.useCallback(() => {
    userGeneralIsEditingSet(false);
  }, []);

  const modalChangePasswordOpen = React.useCallback(() => {
    modalChangePasswordOpenedSet(true);
  }, []);

  const modalChangePasswordClose = React.useCallback(() => {
    modalChangePasswordOpenedSet(false);
  }, []);

  React.useEffect(() => {
    getUserOne()
  }, [
    getUserOne,
  ]);

  return React.useMemo(() => ({
    id,
    userCurrent,
    userOneIsLoading,
    userGeneralIsEditing,
    errors,
    modalChangePasswordOpened,
    userCurrentSet,
    userGeneralFormOpen,
    userGeneralFormClose,
    modalChangePasswordOpen,
    modalChangePasswordClose,
  }), [
    id,
    userCurrent,
    userOneIsLoading,
    userGeneralIsEditing,
    errors,
    modalChangePasswordOpened,
    userCurrentSet,
    userGeneralFormOpen,
    userGeneralFormClose,
    modalChangePasswordOpen,
    modalChangePasswordClose,
  ]);
};
