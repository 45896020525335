import { Navigate, useLocation } from 'react-router-dom';

import { LicenseGuardProps } from './license-guard.props';
import { useLicenseStore } from '@app/stores';


export const LicenseGuardHoc = ({
  children,
}: LicenseGuardProps) => {
  const location = useLocation();
  const { active } = useLicenseStore();

  if (!active) return <Navigate to="/license-expired" />;

  return children;
}
