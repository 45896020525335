import { create } from 'zustand';

import { BreadcrumbType } from '@app/types';


export interface BreadcrumbsStoreInterface {
  breadcrumbs: BreadcrumbType[];
  breadcrumbsHomeSet: () => void;
  breadcrumbsPersonalSet: () => void;
  breadcrumbsMaterialListSet: () => void;
  breadcrumbsMaterialOneSet: (id: number) => void;
  breadcrumbsMaterialCreateSet: () => void;
  breadcrumbsUserListSet: () => void;
  breadcrumbsUserOneSet: (id: number) => void;
  breadcrumbsUserCreateSet: () => void;
  breadcrumbsLicenseSet: () => void;
};

export const useBreadcrumbsStore = create<BreadcrumbsStoreInterface>((set) => ({
  breadcrumbs: [],
  breadcrumbsHomeSet: () => set(() => ({
    breadcrumbs: [{ title: 'Главная', link: '/' }],
  })),
  breadcrumbsPersonalSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Настройки', link: '/' },
    ],
  })),
  breadcrumbsMaterialListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Материалы', link: '/' },
    ],
  })),
  breadcrumbsMaterialOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Материалы', link: '/material-list' },
      { title: 'Материал №' + id, link: '/' },
    ],
  })),
  breadcrumbsMaterialCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Материалы', link: '/material-list' },
      { title: 'Новый материал', link: '/' },
    ],
  })),
  breadcrumbsUserListSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/' },
    ],
  })),
  breadcrumbsUserOneSet: (id: number) => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Пользователь №' + id, link: '/' },
    ],
  })),
  breadcrumbsUserCreateSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Пользователи', link: '/user-list' },
      { title: 'Новый пользователь', link: '/' },
    ],
  })),
  breadcrumbsLicenseSet: () => set(() => ({
    breadcrumbs: [
      { title: 'Главная', link: '/' },
      { title: 'Лицензия', link: '/' },
    ],
  })),
}));
