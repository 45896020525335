import React from 'react';

import { MaterialApi, UserApi } from '@app/api';
import { useAppStore } from '@app/stores';
import { ChangeFieldEventType, UserType } from '@app/types';
import { translateErrorHelper } from '@app/helpers';
import { useMainApi } from '..';

const initialFormValue = {
  id: '',
  name: '',
  frameSrc: '',
  coverSrc: '',
  type: '',
  plug: false,
  priorityOrder: 0,
  users: [],
};

type FormValueType = {
  id: string,
  name: string,
  frameSrc: string,
  coverSrc: string,
  type: string,
  plug: boolean,
  priorityOrder: number,
  users: (string | number)[];
};


export interface UpdateContextInterface {
  formValue: FormValueType | null;
  formDirty: boolean;
  formIsSubmitting: boolean;
  userList: UserType[];
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

export const useUpdateHook = (
): UpdateContextInterface => {
  const [formValue, formValueSet] = React.useState<FormValueType | null>(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [formIsSubmitting, formIsSubmittingSet] = React.useState(false);
  const [ userList, userListSet ] = React.useState<UserType[]>([]);
  const { notifyCall } = useAppStore();

  const {
    materialCurrent,
    errors,
    materialCurrentSet,
    materialGeneralFormClose,
  } = useMainApi();

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => {
      if (state === null) return null;

      return {
        ...state,
        [e.target.name]: e.target.value,
      }
    });
  }, []);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    formIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return;

    const response = await MaterialApi.update(formValue);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось обновить материал',
      });
      formIsSubmittingSet(false);
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Материал успешно обновлен',
    });

    materialCurrentSet(response.data.material);

    formIsSubmittingSet(false);
    materialGeneralFormClose()
  }, [
    formValue,
    notifyCall,
    materialCurrentSet,
    materialGeneralFormClose,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  const userListFetch = React.useCallback(async () => {
    const userList = await UserApi.getList();
    userListSet(userList.data.users);
  }, []);

  React.useEffect(() => {
    if (materialCurrent === null) {
      formValueSet(null);
      return;
    }

    formValueSet({
      ...materialCurrent,
      users: materialCurrent.users.map((item) => item.id),
    });
    userListFetch();
  }, [
    materialCurrent,
    userListFetch,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    userList,
    onSubmit,
    setError,
    changeFormValue,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    userList,
    onSubmit,
    setError,
    changeFormValue,
  ]);
};
