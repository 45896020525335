import React from 'react';
import { useNavigate } from "react-router-dom";
import cs from 'classnames';

import {
  ButtonBrick,
  CardActionsBrick,
  CardBrick,
  CardContentBrick,
  LaunchIcon,
  LinkBrick,
} from "@app/components";

import { OneProps } from './one.props';
import { useMainContext } from "../../context";
import styles from './one.module.scss';


export const OneComponent = ({
  item,
}: OneProps ) => {
  const navigate = useNavigate();
  const [ srcLoading, srcLoadingSet ] = React.useState(true);
  const { themeMode } = useMainContext();

  const classNames = cs(
    styles['one'],
    { [styles['one--loading']]: srcLoading },
    { [styles['one--dark']]: themeMode === 'dark' },
  );

  React.useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = item.coverSrc;

    imageLoader.onload = () => {
      srcLoadingSet(false);
    }
  }, [item]);

  return (
    <div className={classNames}>
      <CardBrick className={styles['one__card']}>
        <CardContentBrick className={styles['one__content']}>
          <div className={styles['one__img']}>
            {item.coverSrc ? <img src={item.coverSrc} alt="Frame" /> : null}
          </div>
          <div className={styles['one__title']}>{item.name}</div>
        </CardContentBrick>
        <CardActionsBrick>
          <div className={styles['one__actions']}>
            {item.type === 'frame' ? (
              <>
                <ButtonBrick onClick={() => {navigate('/' + item.id)}}>Выбрать</ButtonBrick>
                <ButtonBrick
                  startIcon={<LaunchIcon />}
                  onClick={() => {window.open('/' + item.id, '_blank')}}
                >
                  Открыть в новом окне
                </ButtonBrick>
              </>
            ) : (
              <LinkBrick to={item.frameSrc} label='Открыть' />
            )}
          </div>
        </CardActionsBrick>
      </CardBrick>
    </div>
  );
};
