import {
  DialogBrick,
  DialogContentBrick,
  DividerHorizontalBrick,
  ButtonSaveBlock,
  ButtonCancelBlock,
  FieldPasswordBlock,
} from '@app/components';

import {
  useMainApi,
  useChangePasswordApi,
} from '../../context';

import styles from './change-password.module.scss';


export const ChangePasswordComponent = () => {
  const {
    modalChangePasswordOpened,
    modalChangePasswordClose,
  } = useMainApi();

  const {
    formValue,
    formIsSubmitting,
    buttonIsDisabled,
    closeDialog,
    onSubmit,
    changeFormValue,
    setError,
  } = useChangePasswordApi();

  return (
    <div className={styles['change-password']}>
      <DialogBrick
        opened={modalChangePasswordOpened}
        onClose={modalChangePasswordClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        size="medium"
      >
        <div className={styles['change-password__title']}>
          <div>Изменить пароль</div>
          <div className={styles['change-password__buttons']}>
            <ButtonCancelBlock buttonIcon onClick={closeDialog} />
          </div>
        </div>
        <DividerHorizontalBrick />
        <DialogContentBrick>
          <div className="row justify-content-lg-center">
            <div className="col col-lg-6">
              <form
                className={styles['change-password__form']}
                onSubmit={onSubmit}
              >
                <FieldPasswordBlock
                  label="Введите пароль"
                  name="password"
                  value={formValue.password}
                  onChange={changeFormValue}
                  error={setError('password')}
                />
                <FieldPasswordBlock
                  label="Повторите пароль"
                  name="passwordConfirm"
                  value={formValue.passwordConfirm}
                  onChange={changeFormValue}
                  error={setError('passwordConfirm')}
                />
                <ButtonSaveBlock
                  loading={formIsSubmitting}
                  onClick={onSubmit}
                  disabled={buttonIsDisabled}
                />
                <ButtonCancelBlock onClick={closeDialog} />
              </form>
            </div>
          </div>
        </DialogContentBrick>
      </DialogBrick>
    </div>
  );
};
