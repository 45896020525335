import React from 'react';

import { UserType } from '@app/types';
import { useAppStore } from '@app/stores';
import { AuthApi } from '@app/api';


export interface MainContextInterface {
  modalDataUpdateOpened: boolean;
  modalPasswordUpdateOpened: boolean;
  userCurrent: UserType | null;
  modalDataUpdateOpen: () => void;
  modalPasswordUpdateOpen: () => void;
  modalDataUpdateClose: () => void;
  modalPasswordUpdateClose: () => void;
  userCurrentSet: React.Dispatch<React.SetStateAction<UserType | null>>;
};

export const useMainHook = (
): MainContextInterface => {
  const [modalDataUpdateOpened, modalDataUpdateOpenedSet] = React.useState(false);
  const [modalPasswordUpdateOpened, modalPasswordUpdateOpenedSet] = React.useState(false);
  const [userCurrent, userCurrentSet] = React.useState<UserType | null>(null);
  const { notifyCall } = useAppStore();
  
  const getAuthUser = React.useCallback(async () => {
    const response = await AuthApi.current();

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось загрузить пользователя',
      });

      return;
    }

    userCurrentSet(response.data.user);
  }, [
    userCurrentSet,
    notifyCall,
  ]);

  const modalDataUpdateOpen = React.useCallback(() => {
    modalDataUpdateOpenedSet(true);
  }, []);

  const modalDataUpdateClose = React.useCallback(() => {
    modalDataUpdateOpenedSet(false);
  }, []);

  const modalPasswordUpdateOpen = React.useCallback(() => {
    modalPasswordUpdateOpenedSet(true);
  }, []);

  const modalPasswordUpdateClose = React.useCallback(() => {
    modalPasswordUpdateOpenedSet(false);
  }, []);

  React.useEffect(() => {
    getAuthUser()
  }, [
    getAuthUser,
  ]);

  return React.useMemo(() => ({
    modalDataUpdateOpened,
    modalPasswordUpdateOpened,
    userCurrent,
    modalDataUpdateOpen,
    modalDataUpdateClose,
    modalPasswordUpdateOpen,
    modalPasswordUpdateClose,
    userCurrentSet,
  }), [
    modalDataUpdateOpened,
    modalPasswordUpdateOpened,
    userCurrent,
    modalDataUpdateOpen,
    modalDataUpdateClose,
    modalPasswordUpdateOpen,
    modalPasswordUpdateClose,
    userCurrentSet,
  ]);
};
