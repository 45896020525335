import { useAuthStore } from "@app/stores";

import { CardBrick, CardContentBrick } from "@app/components";


export const PanelBottomFeature = () => {
  const { user } = useAuthStore();

  if (!user!.bottomPanelText) return null;
  
  return (
    <CardBrick>
      <CardContentBrick>
        {<div className="html-text" dangerouslySetInnerHTML={{ __html: user!.bottomPanelText }} />}
      </CardContentBrick>
    </CardBrick>
  );
};
