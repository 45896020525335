import {
  ButtonBackBlock,
  ButtonCancelBlock,
  ButtonConfirmComposition,
  ButtonEditBlock,
  CardDataComposition,
} from "@app/components";

import { useMainApi } from '../../context';

export const GeneralComponent = ({
  children,
}: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    id,
    materialOneIsLoading,
    materialGeneralIsEditing,
    materialGeneralFormOpen,
    materialGeneralFormClose,
    materialRemove,
  } = useMainApi();

  if (id === undefined) return null;

  return (
    <CardDataComposition
      title={'Материал №' + id}
      extra={
        <>
          {!materialGeneralIsEditing && <ButtonConfirmComposition onClick={() => materialRemove(Number(id))} size='medium' />}
          {!materialGeneralIsEditing && <ButtonEditBlock onClick={() => materialGeneralFormOpen()} />}
          {!materialGeneralIsEditing && <ButtonBackBlock buttonIcon />}
          {materialGeneralIsEditing && <ButtonCancelBlock onClick={() => materialGeneralFormClose()} buttonIcon />}
        </>
      }
      loading={materialOneIsLoading}
    >
      {children}
    </CardDataComposition>
  );
};
