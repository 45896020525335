import { Navigate } from 'react-router-dom';

import {
  LinkBrick,
  PageCenteringLayout,
  TypographyBrick,
} from '@app/components';

import {
  useAuthStore,
  useLicenseStore,
} from '@app/stores';


export const LicenseExpiredPage = () => {
  const { active } = useLicenseStore();
  const { user } = useAuthStore();
  
  if (active) return <Navigate to='/' />;

  return (
    <PageCenteringLayout>
      <div style={{ display: 'block', textAlign: 'center'}}>
        <TypographyBrick variant="h6">Срок действия лицензии истек.</TypographyBrick>
        {user && user.role === 'admin' && <LinkBrick to="/license" label="Информация об лицензии" />}
      </div>
    </PageCenteringLayout>
  );
};
