import {
  ButtonCancelBlock,
  ButtonSaveBlock,
  FieldBooleanBlock,
  FieldMultiselectBlock,
  // FieldMultiselectBlock,
  FieldNumberBlock,
  FieldSelectBlock,
  FieldTextBlock,
  FormCenteredBlock,
} from "@app/components";

import { useMainApi, useUpdateApi } from '../../context';


export const UpdateComponent = () => {
  const {
    materialGeneralIsEditing,
    materialGeneralFormClose,
  } = useMainApi();

  const {
    formValue,
    formDirty,
    formIsSubmitting,
    userList,
    onSubmit,
    setError,
    changeFormValue,
  } = useUpdateApi();

  if (formValue === null || !materialGeneralIsEditing) return null;

  return (
    <FormCenteredBlock
      onSubmit={onSubmit}
      buttons={
        <>
          <ButtonSaveBlock
            loading={formIsSubmitting}
            onClick={onSubmit}
            disabled={!formDirty}
          />
          <ButtonCancelBlock onClick={materialGeneralFormClose}/>
        </>
      }
    >
      <FieldTextBlock
        name="name"
        label="Название"
        value={formValue.name}
        onChange={changeFormValue}
        error={setError('name')}
      />
      <FieldSelectBlock
        name="type"
        label="Тип"
        value={formValue.type}
        onChange={changeFormValue}
        error={setError('type')}
        items={[
          { label: 'Фрейм', value: 'frame' },
          { label: 'Ссылка', value: 'link' },
        ]}
      />
      <FieldTextBlock
        name="frameSrc"
        label="Frame src"
        value={formValue.frameSrc}
        onChange={changeFormValue}
        error={setError('frameSrc')}
      />
      <FieldTextBlock
        name="coverSrc"
        label="Изображение"
        value={formValue.coverSrc}
        onChange={changeFormValue}
        error={setError('coverSrc')}
      />
      <FieldBooleanBlock
        name="plug"
        label="Заглушка"
        value={formValue.plug}
        onChange={changeFormValue}
        error={setError('coverSrc')}
      />
      <FieldMultiselectBlock
        name="users"
        label="Пользователи"
        value={formValue.users}
        onChange={changeFormValue}
        error={setError('users')}
        items={userList.map((user) => ({ value: user.id, label: user.name + ' ' + (user.lastName || '') }))}
      />
      <FieldNumberBlock
        name="priorityOrder"
        label="Приоритет сортировки"
        value={formValue.priorityOrder}
        onChange={changeFormValue}
        error={setError('priorityOrder')}
      />
    </FormCenteredBlock>
  );
};
