import { create } from 'zustand';

import { NotifyType, ThemeModeType } from '@app/types';
import { PersistanceService } from '@app/services';


export interface AppStoreInterface {
  notify: NotifyType;
  themeMode: ThemeModeType;
  notifyCall: (notify: NotifyType) => void;
  themeModeSet: (themeMode: ThemeModeType) => void;
  drawerOpened: boolean;
  toggleDrawer: () => void;
};

export const useAppStore = create<AppStoreInterface>((set) => ({
  notify: {
    type: 'success',
    message: null,
  },
  themeMode: PersistanceService.getThemeMode(),
  notifyCall: (notify: NotifyType) => set(() => ({
    notify,
  })),
  themeModeSet: (themeMode: ThemeModeType) => {
    set({ themeMode });
    PersistanceService.setThemeMode(themeMode);
  },
  drawerOpened: false,
  toggleDrawer: () => {
    set((state) => ({
      drawerOpened: !state.drawerOpened,
    }));
  },
}));
