import React from 'react';

import { useBreadcrumbsStore } from '@app/stores';
import { PageBaseLayout, PersonalFeature } from '@app/components';


export const PersonalPage = () => {
  const {
    breadcrumbs,
    breadcrumbsPersonalSet,
  } = useBreadcrumbsStore();

  React.useEffect(() => {
    breadcrumbsPersonalSet();
  }, [
    breadcrumbsPersonalSet,
  ]);

  return (
    <PageBaseLayout
      breadcrumbs={breadcrumbs}
      col6
    >
      <PersonalFeature />
    </PageBaseLayout>
  );
};
