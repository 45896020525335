import {
  GeneralComponent,
  TableComponent,
  UpdateComponent,
  ChangePasswordComponent,
} from "..";

import { ChangePasswordProvider, UpdateProvider } from "../../context";


export const MainComponent = () => {
  return (
    <GeneralComponent>
      <TableComponent />

      <UpdateProvider>
        <UpdateComponent />
      </UpdateProvider>

      <ChangePasswordProvider>
        <ChangePasswordComponent />
      </ChangePasswordProvider>
    </GeneralComponent>
  );
};
