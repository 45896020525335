import React from 'react';
import { useParams } from 'react-router-dom';

import { PageBaseLayout, MaterialOneFeature } from '@app/components';
import { useBreadcrumbsStore } from '@app/stores';


export const MaterialOnePage = () => {
  const {
    breadcrumbs,
    breadcrumbsMaterialOneSet,
  } = useBreadcrumbsStore();
  const { id } = useParams();

  React.useEffect(() => {
    breadcrumbsMaterialOneSet(Number(id || 0));
  }, [
    breadcrumbsMaterialOneSet,
    id,
  ]);

  return (
    <PageBaseLayout breadcrumbs={breadcrumbs}>
      <MaterialOneFeature />
    </PageBaseLayout>
  );
};
