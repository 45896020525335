import React from 'react';

import {
  useAuthStore,
  useLicenseStore,
} from '@app/stores';

import {
  AlertBrick,
  SnackbarBrick,
} from '@app/components';


export const LicenseNotifyPart = () => {
  const [ open, openSet ] = React.useState(true);
  const { detail: licenseDetail } = useLicenseStore();
  const { user } = useAuthStore();

  const getDaysLeft = React.useCallback((): number | null => {
    if (licenseDetail === null) return null;
    
    const now = new Date().getTime();
    const licenseDate = Date.parse(String(licenseDetail.expirationDate));
    const daysLeft = Math.floor((licenseDate - now) / 1000 / 60 / 60 / 24);
    
    return Number(daysLeft);
  }, [licenseDetail]);

  const validateWord = React.useCallback((): string | null => {
    const days = getDaysLeft();

    if (days === null) return null;

    let str = 'дней';

    if (days >= 2 && days <= 4) {
      str = 'дня';
    } else if (days === 1) {
      str = 'день';
    }

    return str;
  }, [
    getDaysLeft,
  ]);

  const snackbarIsVisible = React.useMemo(() => {
    const days = getDaysLeft();

    if (days === null || days > 7 || days <= 0 ) return false;

    return true;
  }, [
    getDaysLeft,
  ])

  if (user === null || user.role !== 'admin') return null;

  return (
    <>
      {(snackbarIsVisible) &&
        <SnackbarBrick
          open={open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
        >
          <AlertBrick onClose={() => openSet(false)} severity='info' icon={false}>
            Лицензия закончится через {`${getDaysLeft()} ${validateWord()}`}
          </AlertBrick>
        </SnackbarBrick>
      }
    </>
  );
};
