import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppStore } from '@app/stores';
import { MaterialApi } from '@app/api';
import { MaterialType } from '@app/types';


export interface MainContextInterface {
  currentFrame: MaterialType | null;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const [ currentFrame, currentFrameSet ] = React.useState<MaterialType | null>(null);
  const { notifyCall } = useAppStore();

  const getMaterial = React.useCallback(async () => {
    const response = await MaterialApi.getOne(Number(id));

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось загрузить материал',
      });
    }

    currentFrameSet(response.data.material);
  }, [
    id,
    notifyCall,
  ]);

  React.useEffect(() => {
    getMaterial();
  }, [
    getMaterial,
  ]);

  return React.useMemo(() => ({
    currentFrame,
  }), [
    currentFrame,
  ]);
};
