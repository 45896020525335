import { useNavigate } from 'react-router-dom';

import { useAppStore } from "@app/stores";

import {
  DrawerBrick,
  HomeIcon,
  ListBrick,
  ListIcon,
  ListItemBrick,
  ListItemButtonBrick,
  ListItemIconBrick,
  ManageAccountsIcon,
  VerifiedIcon,
} from "@app/components";


export const DrawerComponent = () => {
  const navigate = useNavigate();
  const { drawerOpened, toggleDrawer } = useAppStore();

  const handleClick = (url: string) => {
    navigate(url);
    toggleDrawer();
  };

  return (
    <DrawerBrick open={drawerOpened} onClose={() => toggleDrawer()}>
      <div style={{ width: '250px'}}>
        <ListBrick>
          <ListItemBrick disablePadding>
            <ListItemButtonBrick onClick={() => handleClick('/')}>
              <ListItemIconBrick><HomeIcon /></ListItemIconBrick>
              Главная
            </ListItemButtonBrick>
          </ListItemBrick>
         
         <ListItemBrick disablePadding>
            <ListItemButtonBrick onClick={() => handleClick('/user-list')}>
              <ListItemIconBrick><ManageAccountsIcon /></ListItemIconBrick>
              Пользователи
            </ListItemButtonBrick>
          </ListItemBrick>
         
          <ListItemBrick disablePadding>
            <ListItemButtonBrick onClick={() => handleClick('/material-list')}>
              <ListItemIconBrick><ListIcon /></ListItemIconBrick>
                Материалы
            </ListItemButtonBrick>
          </ListItemBrick>
         
          <ListItemBrick disablePadding>
            <ListItemButtonBrick onClick={() => handleClick('/license')}>
              <ListItemIconBrick><VerifiedIcon /></ListItemIconBrick>
              Лицензия
            </ListItemButtonBrick>
          </ListItemBrick>
        </ListBrick>
      </div>
    </DrawerBrick>
  );
};
