import {
  CardContentBrick,
  CardDataComposition,
  TypographyBrick,
} from '@app/components'

import { useMainContext } from '../../context';


export const GeneralComponent = () => {
  const {
    userCurrent
  } = useMainContext();
  
  if (userCurrent === null) return null;

  return (
    <CardDataComposition
      title="Пользователь"
      mb12
    >
      <CardContentBrick>
        <TypographyBrick>Электронная почта: {userCurrent.email}</TypographyBrick>
        <TypographyBrick>ФИО: {userCurrent.lastName} {userCurrent.name} {userCurrent.secondName}</TypographyBrick>
      </CardContentBrick>
    </CardDataComposition>
  );
};