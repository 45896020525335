import { 
  ButtonsBrick,
  DialogBrick,
  CardDataComposition,
  ButtonCancelBlock,
  ButtonSaveBlock,
  FormFullwidthBlock,
  FieldTextBlock,
} from '@app/components';

import { useDataUpdateContext, useMainContext } from '../../context';


export const DataUpdateComponent = () => {
  const { modalDataUpdateOpened } = useMainContext();

  const {
    formValue,
    formDirty,
    formIsSubmitting,
    closeModal,
    changeFormValue,
    onSubmit,
    errorSet,
  } = useDataUpdateContext();

  if (formValue === null) return null;

  return (
    <DialogBrick
      opened={modalDataUpdateOpened}
      onClose={closeModal}
      size="small"
    >
      <CardDataComposition
        title="Редактировать данные"
      >
        <FormFullwidthBlock
          onSubmit={onSubmit}
          buttons={
            <ButtonsBrick>
              <ButtonSaveBlock
                onClick={onSubmit}
                disabled={!formDirty}
                loading={formIsSubmitting}
              />
              <ButtonCancelBlock onClick={closeModal} />
            </ButtonsBrick>
          }
        >
          <FieldTextBlock
            label="Фамилия"
            name="lastName"
            value={formValue.lastName}
            onChange={changeFormValue}
            error={errorSet('lastName')}
          />
          <FieldTextBlock
            label="Имя"
            name="name"
            value={formValue.name}
            onChange={changeFormValue}
            error={errorSet('name')}
          />
          <FieldTextBlock
            label="Отчество"
            name="secondName"
            value={formValue.secondName || ''}
            onChange={changeFormValue}
            error={errorSet('secondName')}
          />
        </FormFullwidthBlock>
      </CardDataComposition>
    </DialogBrick>
  );
};
