import { useMainApi } from '../../context';

export const MainComponent = () => {
  const {
    currentFrame,
  } = useMainApi();

  if (currentFrame === null) return null;

  return <iframe
          id="iframe"
          title={currentFrame.name}
          style={{width: '100%', height: '100%'}}
          src={currentFrame.frameSrc}
         ></iframe>;
};