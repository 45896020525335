import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackendErrorsType, ChangeFieldEventType, UserType } from '@app/types';
import { MaterialApi, UserApi } from '@app/api';
import { useAppStore } from '@app/stores';
import { translateErrorHelper } from '@app/helpers';

const initialFormValue = {
  name: '',
  frameSrc: '',
  coverSrc: '',
  type: '',
  plug: false,
  priorityOrder: 0,
  users: [],
};

type FormValueType = {
  name: string,
  frameSrc: string,
  coverSrc: string,
  type: string,
  plug: boolean,
  priorityOrder: number,
  users: (string | number)[];
};


export interface MainContextInterface {
  formValue: FormValueType;
  formDirty: boolean;
  formIsSubmitting: boolean;
  userListIsLoading: boolean;
  userList: UserType[];
  changeFormValue: (e: ChangeFieldEventType) => void;
  onSubmit: (e?: React.SyntheticEvent) => Promise<void>;
  setError: (fieldName: string) => string | null;
};

export const useMainHook = (
): MainContextInterface => {
  const navigate = useNavigate();
  const { notifyCall } = useAppStore();
  const [formValue, formValueSet] = React.useState<FormValueType>(initialFormValue);
  const [formDirty, formDirtySet] = React.useState(false);
  const [formIsSubmitting, formIsSubmittingSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);
  const [ userList, userListSet ] = React.useState<UserType[]>([]);
  const [ userListIsLoading, userListIsLoadingSet ] = React.useState(false);

  const changeFormValue = React.useCallback((e: ChangeFieldEventType): void => {
    formDirtySet(true);
    formValueSet((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const onSubmit = React.useCallback(async (e?: React.SyntheticEvent) => {
    formIsSubmittingSet(true);
    if (e) e.preventDefault();
    if (formValue === null) return;

    const response = await MaterialApi.create(formValue);

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось создать материал',
      });

      formIsSubmittingSet(false);
      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    notifyCall({
      type: 'success',
      message: 'Материал успешно создан',
    });

    navigate('/material-list/' + response.data.material.id);

    formIsSubmittingSet(false);
  }, [
    formValue,
    notifyCall,
    navigate,
  ]);

  const setError = React.useCallback((fieldName: string) => {
    return errors && errors[fieldName] && translateErrorHelper(errors[fieldName][0]);
  }, [
    errors,
  ]);

  const userListFetch = React.useCallback(async () => {
    userListIsLoadingSet(true);
    const userList = await UserApi.getList();
    userListSet(userList.data.users);
    userListIsLoadingSet(false);
  }, []);

  React.useEffect(() => {
    userListFetch();
  }, [
    userListFetch,
  ]);

  return React.useMemo(() => ({
    formValue,
    formDirty,
    formIsSubmitting,
    userListIsLoading,
    userList,
    changeFormValue,
    onSubmit,
    setError,
  }), [
    formValue,
    formDirty,
    formIsSubmitting,
    userListIsLoading,
    userList,
    changeFormValue,
    onSubmit,
    setError,
  ]);
};
