import {
  HttpService,
} from '@app/services';

import {
  BaseResponseInterface,
  UserType,
  HandbkInterface,
} from '@app/types';

import {
  UserCreateRequest,
  UserPasswordUpdateRequest,
  UserUpdateRequest,
} from './types';


export class UserApi {
  public static async create(
    payload: UserCreateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('POST', '/user/create', payload);
  };

  public static async getList(
  ): Promise<BaseResponseInterface<{ users: UserType[] }>> {
    return await HttpService.sendRequest('GET', '/user/list');
  };

  public static async getOne(
    id: number,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('GET', '/user/one/' + id);
  };

  public static async handbk(
    hasAccess?: 'accessConsultant',
  ): Promise<BaseResponseInterface<{ handbk: HandbkInterface }>> {
    return await HttpService.sendRequest('GET', '/user/handbk', { hasAccess });
  };

  public static async update(
    payload: UserUpdateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('PUT', '/user/update', {
      user: payload,
    });
  };

  public static async passwordUpdate(
    payload: UserPasswordUpdateRequest,
  ): Promise<BaseResponseInterface<{ user: UserType }>> {
    return await HttpService.sendRequest('PUT', '/user/password-update', {
     user: payload, 
    });
  };
};
