import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MaterialApi } from '@app/api';
import { useAppStore } from '@app/stores';
import { BackendErrorsType, MaterialType } from '@app/types';


export interface MainContextInterface {
  id: string | undefined;
  materialCurrent: MaterialType | null;
  materialGeneralIsEditing: boolean;
  materialOneIsLoading: boolean;
  errors: BackendErrorsType | null;
  materialRemove: (id: number) => void;
  materialCurrentSet: React.Dispatch<React.SetStateAction<MaterialType | null>>;
  materialGeneralFormOpen: () => void;
  materialGeneralFormClose: () => void;
};

export const useMainHook = (
): MainContextInterface => {
  const { id } = useParams();
  const { notifyCall } = useAppStore();
  const navigate = useNavigate();

  const [materialCurrent, materialCurrentSet] = React.useState<MaterialType | null>(null);
  const [materialOneIsLoading, materialOneIsLoadingSet] = React.useState(false);
  const [materialGeneralIsEditing, materialGeneralIsEditingSet] = React.useState(false);
  const [errors, errorsSet] = React.useState<BackendErrorsType | null>(null);

  const getMaterialOne = React.useCallback(async () => {
    materialOneIsLoadingSet(true);
    const response = await MaterialApi.getOne((Number(id)));

    if (!response.success) {
      notifyCall({
        type: 'error',
        message: 'Не удалось загрузить материал',
      });

      errorsSet(response.errors as BackendErrorsType);
      return;
    }

    materialCurrentSet(response.data.material);
    materialOneIsLoadingSet(false);
  }, [
    id,
    notifyCall,
  ]);

  const materialRemove = React.useCallback(async (id: number) => {
    const response = await MaterialApi.delete(id);

    if (!response.success) {
      return notifyCall({
        type: 'error',
        message: 'Не удалось удалить материал',
      });
    }

    notifyCall({
      type: 'success',
      message: 'Материал успешно удален',
    });

    navigate('/material-list');
  }, [
    navigate,
    notifyCall,
  ]);

  const materialGeneralFormOpen = React.useCallback(() => {
    materialGeneralIsEditingSet(true);
  }, []);

  const materialGeneralFormClose = React.useCallback(() => {
    materialGeneralIsEditingSet(false);
  }, []);

  React.useEffect(() => {
    getMaterialOne()
  }, [
    getMaterialOne,
  ]);

  return React.useMemo(() => ({
    id,
    materialCurrent,
    materialGeneralIsEditing,
    materialOneIsLoading,
    errors,
    materialRemove,
    materialCurrentSet,
    materialGeneralFormOpen,
    materialGeneralFormClose,
  }), [
    id,
    materialCurrent,
    materialGeneralIsEditing,
    materialOneIsLoading,
    errors,
    materialRemove,
    materialCurrentSet,
    materialGeneralFormOpen,
    materialGeneralFormClose,
  ]);
};
