import {
  PanelWelcomeFeature,
  PanelTopFeature,
  PanelBottomFeature,
  MaterialListForUserFeature,
} from "@app/components";

export const HomeFeature = () => {
  return (
    <>
      <PanelWelcomeFeature />
      <PanelTopFeature />
      <MaterialListForUserFeature />
      <PanelBottomFeature />
    </>
  );
};
