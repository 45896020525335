import { HttpService } from '@app/services';
import { BaseResponseInterface } from '@app/types';

import { LicenseCheckActiveResponse } from './types';


export class LicenseApi {
  public static async checkActive(
  ): Promise<BaseResponseInterface<LicenseCheckActiveResponse>> {
    return await HttpService.sendRequest('GET', '/license/check-active');
  };
};
