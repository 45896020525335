import { HttpService } from '@app/services';

import {
  BaseResponseInterface,
  MaterialType,
} from '@app/types';

import {
  MaterialCreateRequest,
  MaterialUpdateRequest,
} from './types';


export class MaterialApi {
  public static async create(
    payload: MaterialCreateRequest,
  ): Promise<BaseResponseInterface<{ material: MaterialType }>> {
    return await HttpService.sendRequest('POST', '/material/create', payload);
  };

  public static async getList(
  ): Promise<BaseResponseInterface<{ materials: MaterialType[] }>> {
    return await HttpService.sendRequest('GET', '/material/list');
  };

  public static async getOne(
    id: number,
  ): Promise<BaseResponseInterface<{ material: MaterialType }>> {
    return await HttpService.sendRequest('GET', '/material/one/' + id);
  };

  public static async update(
    payload: MaterialUpdateRequest,
  ): Promise<BaseResponseInterface<{ material: MaterialType }>> {
    return await HttpService.sendRequest('PUT', '/material/update', {
      material: payload,
    });
  };

  public static async delete(
    id: number,
  ): Promise<BaseResponseInterface<{ id: number }>> {
    return await HttpService.sendRequest('DELETE', '/material/delete', { id });
  };

  public static async getMaterialForUserList(
  ): Promise<BaseResponseInterface<{ materials: MaterialType[] }>> {
    return await HttpService.sendRequest('GET', '/material/list-by-user');
  };
};
